var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{on:{"click":function($event){return _vm.$refs.file.click()}}},[_c('b-button',{staticClass:"button"},[_c('input',{ref:"file",attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.uploadImage($event)}}}),_vm._v(" Seleccionar imagen ")])],1),_c('transition',{attrs:{"name":"modal"}},[(_vm.showModal)?_c('div',{staticClass:"modal_ticket",attrs:{"id":"modal_ticket"}},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-container"},[_c('content',{attrs:{"select":".modal-header"}},[_c('div',{staticClass:"modal-header"},[_c('h4',{staticStyle:{"margin":"auto"}},[_vm._v("Editar")])])]),_c('div',{staticClass:"modal-body text--center"},[_c('div',{staticClass:"upload-example"},[_c('Cropper',{staticClass:"upload-example-cropper",attrs:{"stencil-size":{
                  width: 300,
                  height: 100
                },"stencil-props":{
                  handlers: {},
                  movable: true,
                  resizable: true,
                  width: 300,
                  height: 100
                  // aspectRatio: 1
                },"src":_vm.imageCrop,"size-restrictions-algorithm":_vm.pixelsRestriction},on:{"change":_vm.onChange}}),_c('div',{staticClass:"button-wrapper"})],1),_c('p',[_vm._v("Para acercar o alejar haga scroll sobre la imagen")]),_c('b-button',{on:{"click":_vm.savePicture}},[_vm._v(" Recortar imagen ")]),_c('b-button',{on:{"click":_vm.cancelPopup}},[_vm._v(" Cancelar ")])],1)])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }