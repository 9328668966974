<template>
  <div>
    <div @click="$refs.file.click()">
      <b-button class="button">
        <input
          type="file"
          ref="file"
          @change="uploadImage($event)"
          accept="image/*"
        />
        Seleccionar imagen
      </b-button>
    </div>
    <transition name="modal">
      <div id="modal_ticket" class="modal_ticket" v-if="showModal">
        <div class="modal-wrapper">
          <div class="modal-container">
            <content select=".modal-header">
              <div class="modal-header">
                <h4 style="margin: auto">Editar</h4>
              </div>
            </content>
            <div class="modal-body text--center">
              <div class="upload-example">
                <Cropper
                  class="upload-example-cropper"
                  :stencil-size="{
                    width: 300,
                    height: 100
                  }"
                  :stencil-props="{
                    handlers: {},
                    movable: true,
                    resizable: true,
                    width: 300,
                    height: 100
                    // aspectRatio: 1
                  }"
                  :src="imageCrop"
                  :size-restrictions-algorithm="pixelsRestriction"
                  @change="onChange"
                />
                <div class="button-wrapper"></div>
              </div>
              <p>Para acercar o alejar haga scroll sobre la imagen</p>
              <b-button @click="savePicture"> Recortar imagen </b-button>
              <b-button @click="cancelPopup"> Cancelar </b-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "trazo-image-crop",
  data() {
    return {
      showModal: false,
      imageCrop: null,
      imageCropped: null
    };
  },
  components: {
    Cropper
  },
  beforeMount() {},
  methods: {
    seeModal(event) {
      event.preventDefault();
      this.$refs.file.click();
      //this.showModal = true;
    },
    cancelPopup(event) {
      event.preventDefault();
      this.showModal = false;
      this.imageCrop = null;
      this.imageCropped = null;
      this.$refs.file.value = "";
      this.$emit("showModal", this.showModal);
    },
    savePicture(event) {
      event.preventDefault();
      function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || "";
        sliceSize = sliceSize || 512;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        for (
          var offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);
          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
      }
      const ImageURL = this.imageCropped;
      const block = ImageURL.split(";");
      const contentType = block[0].split(":")[1];
      const realData = block[1].split(",")[1];
      // const type = ImageURL.split(";")[0].split("/")[1];
      const blob = b64toBlob(realData, contentType);
      this.$emit("selectImage", {
        dataUrl: this.imageCropped,
        blob,
        name:
          "photo." +
          ImageURL.substring(
            ImageURL.indexOf("/") + 1,
            ImageURL.indexOf(";base64")
          )
      });
      this.showModal = false;
      this.$emit("showModal", this.showModal);
    },
    uploadImage(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.imageCrop = e.target.result;
          this.showModal = true;
          this.$emit("showModal", this.showModal);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    onChange({ coordinates, canvas }) {
      this.coordinates = coordinates;
      this.imageCropped = canvas.toDataURL();
    },
    pixelsRestriction() {
      return {
        minWidth: 300 || this.$props["minWidth"],
        minHeight: 100 || this.$props["minHeight"]
        // maxWidth: 300 || this.$props["maxWidth"],
        // maxHeight: 100 || this.$props["maxHeight"]
      };
    }
  }
};
</script>

<style>
.upload-example-cropper {
  border: solid 1px #eee;
  height: 200px;
  width: 100%;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

.button input {
  display: none;
}
</style>
